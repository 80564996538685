import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/dashboard',
    component: loadable(() => import('pages/erp/dashboard')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // 供應商管理
  {
    path: '/vends',
    component: loadable(() => import('pages/erp/vends/readlist')),
    exact: true,
  },
  {
    path: '/vends/create',
    component: loadable(() => import('pages/erp/vends/create')),
    exact: true,
  },
  {
    path: '/vends/:id',
    component: loadable(() => import('pages/erp/vends/read')),
    exact: true,
  },
  {
    path: '/vends/:id/update',
    component: loadable(() => import('pages/erp/vends/update')),
    exact: true,
  },

  // 客戶管理
  {
    path: '/custs',
    component: loadable(() => import('pages/erp/custs/readlist')),
    exact: true,
  },
  {
    path: '/custs/create',
    component: loadable(() => import('pages/erp/custs/create')),
    exact: true,
  },
  {
    path: '/custs/:id',
    component: loadable(() => import('pages/erp/custs/read')),
    exact: true,
  },
  {
    path: '/custs/:id/update',
    component: loadable(() => import('pages/erp/custs/update')),
    exact: true,
  },

  // 物料管理
  {
    path: '/mats/all',
    component: loadable(() => import('pages/erp/mats/readlist')),
    exact: true,
  },
  {
    path: '/mats/fgi',
    component: loadable(() => import('pages/erp/mats/readlist')),
    exact: true,
  },
  {
    path: '/mats/fgiAndAccs',
    component: loadable(() => import('pages/erp/mats/readlist')),
    exact: true,
  },
  {
    path: '/mats/accsAndOem',
    component: loadable(() => import('pages/erp/mats/readlist')),
    exact: true,
  },
  {
    path: '/mats/create',
    component: loadable(() => import('pages/erp/mats/create')),
    exact: true,
  },
  {
    path: '/mats/:id',
    component: loadable(() => import('pages/erp/mats/read')),
    exact: true,
  },
  {
    path: '/mats/:id/update',
    component: loadable(() => import('pages/erp/mats/update')),
    exact: true,
  },
  {
    path: '/mats/:id/matInjs/create',
    component: loadable(() => import('pages/erp/mats/read/matInjs/create')),
    exact: true,
  },
  {
    path: '/mats/matInjs/:id/update',
    component: loadable(() => import('pages/erp/mats/read/matInjs/update')),
    exact: true,
  },
  {
    path: '/mats/matInjs/:id',
    component: loadable(() => import('pages/erp/mats/read/matInjs/read')),
    exact: true,
  },

  // 庫存異動
  {
    path: '/docs',
    component: loadable(() => import('pages/erp/docs/readlist')),
    exact: true,
  },
  {
    path: '/docs/receive',
    component: loadable(() => import('pages/erp/docs/receive')),
    exact: true,
  },
  {
    path: '/docs/issue',
    component: loadable(() => import('pages/erp/docs/issue')),
    exact: true,
  },
  {
    path: '/docs/transfer',
    component: loadable(() => import('pages/erp/docs/transfer')),
    exact: true,
  },
  {
    path: '/docs/last',
    component: loadable(() => import('pages/erp/docs/last')),
    exact: true,
  },
  {
    path: '/docs/:id',
    component: loadable(() => import('pages/erp/docs/read')),
    exact: true,
  },

  // 模具管理
  {
    path: '/tools/molds',
    component: loadable(() => import('pages/erp/tools/molds/readlist')),
    exact: true,
  },
  {
    path: '/tools/molds/create',
    component: loadable(() => import('pages/erp/tools/molds/create')),
    exact: true,
  },
  {
    path: '/tools/molds/:id',
    component: loadable(() => import('pages/erp/tools/molds/read')),
    exact: true,
  },
  {
    path: '/tools/molds/:id/update',
    component: loadable(() => import('pages/erp/tools/molds/update')),
    exact: true,
  },

  // 模具管理
  {
    path: '/tools/jgs',
    component: loadable(() => import('pages/erp/tools/jgs/readlist')),
    exact: true,
  },
  {
    path: '/tools/jgs/create',
    component: loadable(() => import('pages/erp/tools/jgs/create')),
    exact: true,
  },
  {
    path: '/tools/jgs/:id',
    component: loadable(() => import('pages/erp/tools/jgs/read')),
    exact: true,
  },
  {
    path: '/tools/jgs/:id/update',
    component: loadable(() => import('pages/erp/tools/jgs/update')),
    exact: true,
  },

  // 採購管理
  {
    path: '/pos',
    component: loadable(() => import('pages/erp/pos/readlist')),
    exact: true,
  },
  {
    path: '/posItems',
    component: loadable(() => import('pages/erp/pos/readlistItems')),
    exact: true,
  },
  {
    path: '/pos/create',
    component: loadable(() => import('pages/erp/pos/create')),
    exact: true,
  },
  {
    path: '/pos/:id',
    component: loadable(() => import('pages/erp/pos/read')),
    exact: true,
  },
  {
    path: '/pos/:id/update',
    component: loadable(() => import('pages/erp/pos/update')),
    exact: true,
  },
  {
    path: '/pos/:id/print',
    component: loadable(() => import('pages/erp/pos/print')),
    exact: true,
  },
  {
    path: '/pos/:id/printRx',
    component: loadable(() => import('pages/erp/pos/printRx')),
    exact: true,
  },

  // 採購請款管理管理
  {
    path: '/poInvcs',
    component: loadable(() => import('pages/erp/poInvcs/readlist')),
    exact: true,
  },
  {
    path: '/poInvcs/create',
    component: loadable(() => import('pages/erp/poInvcs/create')),
    exact: true,
  },
  {
    path: '/poInvcs/:id',
    component: loadable(() => import('pages/erp/poInvcs/read')),
    exact: true,
  },
  {
    path: '/poInvcs/:id/update',
    component: loadable(() => import('pages/erp/poInvcs/update')),
    exact: true,
  },
  {
    path: '/poInvcs/:id/print',
    component: loadable(() => import('pages/erp/poInvcs/print')),
    exact: true,
  },

  // 庫存清單管理
  {
    path: '/stocklists',
    component: loadable(() => import('pages/erp/stocklists/readlist')),
    exact: true,
  },
  {
    path: '/stocklists/own',
    component: loadable(() => import('pages/erp/stocklists/readlist')),
    exact: true,
  },
  {
    path: '/stocklists/shared',
    component: loadable(() => import('pages/erp/stocklists/readlist')),
    exact: true,
  },
  {
    path: '/stocklists/create',
    component: loadable(() => import('pages/erp/stocklists/create')),
    exact: true,
  },
  {
    path: '/stocklists/:id',
    component: loadable(() => import('pages/erp/stocklists/read')),
    exact: true,
  },
  {
    path: '/stocklists/:id/update',
    component: loadable(() => import('pages/erp/stocklists/update')),
    exact: true,
  },

  // 訂單管理
  {
    path: '/sos',
    component: loadable(() => import('pages/erp/sos/readlist')),
    exact: true,
  },
  {
    path: '/sos/openOrders',
    component: loadable(() => import('pages/erp/sos/readlist')),
    exact: true,
  },
  {
    path: '/sosItems',
    component: loadable(() => import('pages/erp/sos/readlistItems')),
    exact: true,
  },
  {
    path: '/sosItems/openOrders',
    component: loadable(() => import('pages/erp/sos/readlistItems')),
    exact: true,
  },
  {
    path: '/sos/create',
    component: loadable(() => import('pages/erp/sos/create')),
    exact: true,
  },
  {
    path: '/sos/:id',
    component: loadable(() => import('pages/erp/sos/read')),
    exact: true,
  },
  {
    path: '/sos/:id/update',
    component: loadable(() => import('pages/erp/sos/update')),
    exact: true,
  },
  {
    path: '/sos/:id/print',
    component: loadable(() => import('pages/erp/sos/print')),
    exact: true,
  },

  // 工單管理
  {
    path: '/wos',
    component: loadable(() => import('pages/erp/wos/readlist')),
    exact: true,
  },
  {
    path: '/wos/openWos',
    component: loadable(() => import('pages/erp/wos/readlist')),
    exact: true,
  },
  {
    path: '/wos/create',
    component: loadable(() => import('pages/erp/wos/create')),
    exact: true,
  },
  {
    path: '/wos/:id',
    component: loadable(() => import('pages/erp/wos/read')),
    exact: true,
  },
  {
    path: '/wos/:id/update',
    component: loadable(() => import('pages/erp/wos/update')),
    exact: true,
  },
  {
    path: '/wos/:id/print',
    component: loadable(() => import('pages/erp/wos/print')),
    exact: true,
  },
  {
    path: '/wos/:id/printOld',
    component: loadable(() => import('pages/erp/wos/printOld')),
    exact: true,
  },

  // 出貨管理
  {
    path: '/delivs',
    component: loadable(() => import('pages/erp/delivs/readlist')),
    exact: true,
  },
  {
    path: '/delivs/last',
    component: loadable(() => import('pages/erp/delivs/last')),
    exact: true,
  },
  {
    path: '/delivs/create',
    component: loadable(() => import('pages/erp/delivs/create')),
    exact: true,
  },
  {
    path: '/delivs/:id',
    component: loadable(() => import('pages/erp/delivs/read')),
    exact: true,
  },
  {
    path: '/delivs/:id/update',
    component: loadable(() => import('pages/erp/delivs/update')),
    exact: true,
  },
  {
    path: '/delivs/:id/print',
    component: loadable(() => import('pages/erp/delivs/print')),
    exact: true,
  },

  // 請款管理
  {
    path: '/invcs',
    component: loadable(() => import('pages/erp/invcs/readlist')),
    exact: true,
  },
  {
    path: '/invcs/create',
    component: loadable(() => import('pages/erp/invcs/create')),
    exact: true,
  },
  {
    path: '/invcs/:id',
    component: loadable(() => import('pages/erp/invcs/read')),
    exact: true,
  },
  {
    path: '/invcs/:id/update',
    component: loadable(() => import('pages/erp/invcs/update')),
    exact: true,
  },
  {
    path: '/invcs/:id/print',
    component: loadable(() => import('pages/erp/invcs/print')),
    exact: true,
  },
  {
    path: '/invcs/:id/printCi',
    component: loadable(() => import('pages/erp/invcs/printCi')),
    exact: true,
  },
  {
    path: '/invcs/:id/printPL',
    component: loadable(() => import('pages/erp/invcs/printPL')),
    exact: true,
  },
  {
    path: '/invcs/:id/printPLBarcode',
    component: loadable(() => import('pages/erp/invcs/printPLBarcode')),
    exact: true,
  },

  // 報表
  {
    path: '/reports/woProcKpi',
    component: loadable(() => import('pages/erp/reports/woProcKpi')),
    exact: true,
  },
  {
    path: '/reports/woWeeklyValue',
    component: loadable(() => import('pages/erp/reports/woWeeklyValue')),
    exact: true,
  },
  {
    path: '/reports/woDefectRate',
    component: loadable(() => import('pages/erp/reports/woDefectRate')),
    exact: true,
  },

  // 後台管理者
  {
    path: '/admins',
    component: loadable(() => import('pages/erp/admins/readlist')),
    exact: true,
  },
  {
    path: '/admins/create',
    component: loadable(() => import('pages/erp/admins/create')),
    exact: true,
  },
  {
    path: '/admins/:id',
    component: loadable(() => import('pages/erp/admins/read')),
    exact: true,
  },
  {
    path: '/admins/:id/update',
    component: loadable(() => import('pages/erp/admins/update')),
    exact: true,
  },

  // 參數管理
  {
    path: '/params',
    component: loadable(() => import('pages/erp/params/readlist')),
    exact: true,
  },
  {
    path: '/params/create',
    component: loadable(() => import('pages/erp/params/create')),
    exact: true,
  },
  {
    path: '/params/:id',
    component: loadable(() => import('pages/erp/params/read')),
    exact: true,
  },
  {
    path: '/params/:id/update',
    component: loadable(() => import('pages/erp/params/update')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
